var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "div",
        { staticClass: "card" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Sensitivity.Title")))]),
          _c("SensitivitySelect"),
          !_vm.sensitivityMatch
            ? _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("Card.Sensitivity.Updating")) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }