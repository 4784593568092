


















import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  data() {
    return {
      sensitivityOptions: [
        { value: 0, label: 'VeryLow' },
        { value: 1, label: 'Low' },
        { value: 2, label: 'Medium' },
        { value: 3, label: 'High' },
        { value: 4, label: 'VeryHigh' }
      ]
    }
  },
  computed: {
    ...mapGetters('thing', ['sensitivityLevel'])
  },
  methods: {
    setSensitivity(sensitivityLevel: number) {
      this.$store.dispatch('thing/setSensitivity', sensitivityLevel)
    }
  }
})
