var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c(
      "div",
      { staticClass: "grid grid--things" },
      [
        _c("SelectThing", { staticClass: "select-thing" }),
        _c("Thing"),
        _c("Sensitivity"),
        _c("Users"),
        _c("Groups"),
        _c("Client"),
        _c("Live", { staticClass: "live" }),
        _c("Strength"),
        _c("Quality")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }