var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "article",
        { staticClass: "card" },
        [_c("h1", [_vm._v(_vm._s(_vm.$t("Card.Thing.Title")))]), _c("Table")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }