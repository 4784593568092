var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      label: "label",
      clearable: false,
      options: _vm.sensitivityOptions.map(function(option) {
        return {
          value: option.value,
          label: _vm.$t("Card.Sensitivity." + option.label)
        }
      }),
      reduce: function(item) {
        return item.value
      },
      value: _vm.sensitivityLevel
    },
    on: { input: _vm.setSensitivity }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }